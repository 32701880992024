import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

export default function FormDialog(props) {
  //props should contain a default session if EDIT props.
  // 
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    
    setOpen(true);
    
  };

  const handleClose = () => {
    setOpen(false);
    props.openHandler();
  };
  function handleAddOrEdit(jo) {
    
    // Code to handle login goes here

    props.acceptCallBack(jo)
    handleClose();
  
}

function normalizeDate(aDateString) {
  var d = new Date(aDateString.replace('.000Z', ''))
  d.setHours(0, 0, 0, 0)
  return d.toDateString()
}
  return (
    <React.Fragment>
      
       <MenuItem onClick={handleClickOpen}>Admin Settings...</MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
     //       const date = formJson.date;
            handleAddOrEdit(formJson);
          },
        }}
      >
        <DialogTitle>Admin Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Defaults
          </DialogContentText>
          
          
          <TextField required  margin="dense" id="NumberOfDaysToShow"  label="Days to Show" name="NumberOfDaysToShow"  defaultValue={props.properties.NumberOfDaysToShow}  type="Number" fullWidth  variant="outlined" />
          <TextField required  margin="dense" id="SundayDefaultStart"  label="Sunday Default start" name="SundayDefaultStart" defaultValue={props.properties.SundayDefaultStart}    type="text" fullWidth  variant="outlined" />
          <TextField required margin="dense" id="SundayDescription" label="Sunday Description"   name="SundayDescription" type="text" defaultValue={props.properties.SundayDescription}  fullWidth variant="outlined" />
          <TextField required margin="dense" id="SaturdayDefaultStart" label="Saturday Default start" name="SaturdayDefaultStart" type="text" defaultValue={props.properties.SaturdayDefaultStart}  fullWidth variant="outlined" />
          <TextField required margin="dense" id="SaturdayDescription" label="Saturday Description" name="SaturdayDescription" type="text" defaultValue={props.properties.SaturdayDescription}  fullWidth variant="outlined" />
         
          <TextField required margin="dense" id="IncludeSaturdayRecRow" label="Include Saturday Rec Row" name="IncludeSaturdayRecRow" type="text" defaultValue={props.properties.IncludeSaturdayRecRow}  fullWidth variant="outlined" />
          <TextField required margin="dense" id="SaturdayRecDescription" label="Saturday Rec Description" name="SaturdayRecDescription" type="text" defaultValue={props.properties.SaturdayRecDescription}  fullWidth variant="outlined" />
          <TextField  required margin="dense" id="SaturdayRecRowStart"  label="Saturday Rec Row Start" name="SaturdayRecRowStart"  defaultValue={props.properties.SaturdayRecRowStart}  type="text" fullWidth  variant="outlined" />
         
          <TextField datatype='String' required margin="dense" id="OpenRowDescription"  label="Open Row Description" name="OpenRowDescription"  type="text" defaultValue={props.properties.OpenRowDescription}  fullWidth variant="outlined" />
          <TextField  sx={{width:'0px'}}  required margin="dense" id="_id"  name="_id" hiddenLabel={true} type="hidden" defaultValue={props.properties._id}  fullWidth variant="standard" />
          
          <TextField sx={{width:'0px'}}  required margin="dense"  hiddenLabel={true} type="hidden"   id="Name"  name="Name" defaultValue={props.properties.Name} fullWidth variant="standard" />
        
                  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Accept</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}