import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from "@mui/icons-material/Menu";
import PropertiesUpdateDialog from "./PropertiesUpdateDialog"
import PurgeOldDialog from "./PurgeOldData"


export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    props.logoutParent()
  }
  const handleAccept = (jo) => {
    setAnchorEl(null);
    props.acceptCallBack(jo)
  }
  const handleShowToday = () => {
    setAnchorEl(null);
    props.setShowToday();
  }


  return (
    <div>
      <MenuIcon
        id="basic-button"

        onClick={handleClick}
      >
      </MenuIcon>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.isAdmin ?
          <div>
            <PropertiesUpdateDialog properties={props.properties} acceptCallBack={handleAccept} openHandler={handleClose}></PropertiesUpdateDialog>
            <PurgeOldDialog openHandler={handleClose}></PurgeOldDialog>
          </div>
          : null
        }
        <MenuItem><a href="https://www.weatherbug.com/weather-forecast/hourly/fort-mill-sc-29715">Weather Bug</a></MenuItem>
        <MenuItem><a href="https://www.windy.com/35.074/-81.057/wind?35.055,-81.057,13">Windy</a> </MenuItem>
        <MenuItem onClick={handleShowToday}> Hide/Show Today </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        
      </Menu>
    </div>
  );
}