import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
export default function FormDialog(props) {
  //props should contain a default session if EDIT props.
  // 
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleAddOrEdit(jo) {

    // Code to handle login goes here

    props.acceptCallBack(jo)
    handleClose();

  }

  function normalizeDate(aDateString) {
    var d = new Date(aDateString.replace('.000Z', ''))
    d.setHours(0, 0, 0, 0)
    return d.toDateString()
  }
  return (
    <React.Fragment>

      <IconButton onClick={() => handleClickOpen()} variant="outlined" edge="end" aria-label="edit">
       
      <EditIcon  htmlColor='white' /> 
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            //       const date = formJson.date;
            handleAddOrEdit(formJson);
          },
        }}
      >
        <DialogTitle>Edit a Rowing Session Signup</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sign up
          </DialogContentText>
          <TextField InputProps={{ readOnly: true }} autoFocus required margin="dense" id="Date" label="Date" name="Date" defaultValue={normalizeDate(props.rowingSession.Date)} type="text" fullWidth variant="outlined" />
          <TextField InputProps={{ readOnly: true }} required margin="dense" id="FirstName" label="FirstName" name="FirstName" defaultValue={props.rowingSession.FirstName} type="text" fullWidth variant="outlined" />
          <TextField InputProps={{ readOnly: true }} required margin="dense" id="LastName" label="LastName" name="LastName" defaultValue={props.rowingSession.LastName} type="text" fullWidth variant="outlined" />
          <TextField required margin="dense" id="Quantity" label="Quantity" name="Quantity" defaultValue={props.rowingSession.Quantity} type="Number" fullWidth variant="outlined" />
          <TextField autoFocus margin="dense" id="Comment" label="Comment" name="Comment" defaultValue={props.rowingSession.Comment} type="text" fullWidth variant="outlined" />
          <TextField sx={{width:'0px'}}  datatype='Number' required margin="dense" id="Order" name="Order" type="hidden" defaultValue={props.rowingSession.Order} fullWidth variant="standard" />
          <TextField sx={{width:'0px'}}  datatype='String' required margin="dense" id="_id" name="_id" type="hidden" defaultValue={props.rowingSession._id} fullWidth variant="standard" />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Accept</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}