import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const sweepStyle = [
    'Port' ,
   'Starboard' ,
   'Bi-sweeptual' ,
    'Neither' 
  ]


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function handleLogin(jo) {

    // Code to handle login goes here
    props.setUserInParent(jo)
    handleClose();

  }
  return (
    <React.Fragment>
      {props.currentUser == null || props.currentUser.FirstName === "" ?
        <Button variant="outlined" onClick={handleClickOpen} color="inherit">SignUp</Button>
        : "Hi " + props.currentUser.FirstName +' ' +props.currentUser.LastName.charAt(0)}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            //       const date = formJson.date;
            handleLogin(formJson);
          },
        }}
      >
        <DialogTitle>Sign Up</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Sign up for CYC Rowing Sessions,<br /> <b>Your browser must have cookies enabled</b>
          </DialogContentText>
          <TextField autoFocus required margin="dense" id="FirstName" label="First Name" name="FirstName" type="text" fullWidth variant="outlined" />
          <TextField required margin="dense" id="LastName" label="Last Name" name="LastName" type="text" fullWidth variant="outlined" />
          <TextField required margin="dense" id="Email" label="Email" name="Email" type="email" fullWidth variant="outlined" />
        {/*
          <Autocomplete   id="combo-box" options={sweepStyle} renderInput={(params) => <TextField variant="standard"  required  {...params} id="SweepStyle" label="Sweep Style"  name="SweepStyle"/>} 
           ListboxProps={{style:{ maxHeight: '120px', border: '1px solid blue'  }}}/>
      */}
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" >Sign In</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}